// Ajax CSRF
$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  },
});

// Active Nav
function activeMenu() {
  let cUrl = String(window.location).split('?')[0];
  if (cUrl.substr(cUrl.length - 1) === '#') {
    cUrl = cUrl.slice(0, -1);
  }
  const action = $($(this))[0].href;
  if (action === cUrl) {
    $(this).addClass('active');
    $(this).parents('ul').add(this).each(function () {
      $(this).parent().addClass('open');
    });
    return true;
  }
  if (action !== (`${window.location.origin}/`)) {
    if (cUrl.indexOf(action) === 0) {
      $(this).addClass('active');
      $(this).parents('ul').add(this).each(function () {
        $(this).parent().addClass('open');
      });
      return true;
    }
  }
  return false;
}

$(() => {
  // Add class .active to current link of Navbar, break after first occurrence
  $('#navbar-main').find('a').each(function () {
    const active = activeMenu.call(this);
    if (active) {
      return false;
    }
  });

  // On Hover Tooltip del Placeholder
  $('input').hover(function () {
    const titleText = $(this).attr('placeholder');
    if (titleText) {
      $(this).tooltip({
        title: titleText,
        trigger: 'hover focus',
        placement: 'bottom',
      });
    }
  });

  $('#form-delete').submit(() => {
    const objeto = window.location.pathname.split('/')[1];
    return confirm(`Esta seguro que desea eliminar el ${objeto}`);
  });

  $('#form-restore').submit(() => {
    const objeto = window.location.pathname.split('/')[1];
    return confirm(`Esta seguro que desea restaurar el ${objeto}`);
  });

  // Toggle de Evento Wi-Fi
  $('#activeWifiToggle').change(function () {
    const fields = $(this).data('fields');
    if ($(this).prop('checked') === true) {
      $.each(fields, (key, value) => {
        $(`#${value}`).attr('disabled', false);
      });
    } else {
      $.each(fields, (key, value) => {
        $(`#${value}`).attr('disabled', true);
      });
    }
  });
});
