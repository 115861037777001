window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('bootstrap4-toggle');
window.dt = require('datatables.net-bs4');
require('select2');
window.flatpickr = require('flatpickr');
const Spanish = require('flatpickr/dist/l10n/es.js').default.es;

window.flatpickr.setDefaults({
  locale: {
    firstDayOfWeek: 0,
  },
  allowInput: true,
  // disableMobile: true
});
window.flatpickr.localize(Spanish);

$(() => {
  // Logout form
  $('#logout-link').click((event) => {
    event.preventDefault();
    document.getElementById('logout-form').submit();
  });

  // Prevent multiple submits on form
  $(document).on('submit', 'form', function () {
    $(this).find('button:submit, input:submit').attr('disabled', 'disabled');
  });

  // Select2
  $.fn.select2.defaults.set('theme', 'bootstrap4');
  $('.select2').select2();
  $('.select2-multiple').select2({
    allowClear: true,
  });
  $('.select2-ips').select2({
    placeholder: 'IPs Autorizados',
    tags: true,
    tokenSeparators: [',', ' '],
  });
  $('.select2-salones').select2({
    placeholder: 'Salones del Evento',
    tokenSeparators: [',', ' '],
  });

  // Date Time Picker
  $('.date-picker').flatpickr({
    altInput: true,
  });
  $('.datetime-picker').flatpickr({
    altInput: false,
    enableTime: true,
    enableSeconds: true,
  });

  // DataTables
  const dataTableOptions = {
    iDisplayLength: 25,
    dom: "<'container-fluid'<'row d-print-none'<'col-sm-6'l><'col-sm-6'f>>>"
      + "<'table-responsive'tr>"
      + "<'container-fluid'<'row d-print-none'<'col-sm-5'i><'col-sm-7'p>>>",
    language: {
      sProcessing: 'Procesando...',
      sLengthMenu: 'Mostrar _MENU_ registros',
      sZeroRecords: 'No se encontraron resultados',
      sEmptyTable: 'Ningún dato disponible en esta tabla',
      sInfo: '_START_ – _END_ / _TOTAL_ registros',
      sInfoEmpty: '0 registros',
      sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
      sInfoPostFix: '',
      sSearch: 'Buscar:',
      sUrl: '',
      sInfoThousands: ',',
      sLoadingRecords: 'Cargando...',
      oPaginate: {
        sFirst: 'Primero',
        sLast: 'Último',
        sNext: 'Siguiente',
        sPrevious: 'Anterior',
      },
      oAria: {
        sSortAscending: ': Activar para ordenar la columna de manera ascendente',
        sSortDescending: ': Activar para ordenar la columna de manera descendente',
      },
    },
    initComplete() {
      this.api().columns().every(function () {
        const column = this;
        if ($(column.header()).data('orderable') !== false) {
          const select = $('<select class="form-control"><option value="">Mostrar todos</option></select>')
            .appendTo($(column.footer()).empty())
            .on('change', function () {
              const val = $.fn.dataTable.util.escapeRegex(
                $(this).val(),
              );
              column.search(val ? `^${val}$` : '', true, false).draw();
            });

          column.data().unique().sort().each((d) => {
            const val = $('<div/>').html(d).text();
            select.append(`<option value="${val}">${val}</option>`);
          });
        }
      });
    },
  };
  $('.dataTable').DataTable(dataTableOptions);
});
